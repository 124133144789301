.NavBar {
    display: flex;
    justify-content: center;
}

.emailz a {
    font-style: italic;
}

.socialIconList {
    margin-top: 10px;
    display: flex;
    grid-gap: min(calc(1050px + 0.4vw), 32px);
    
}

.socialIconList a {
    font-size: min(calc(1050px + 0.4vw), 32px);
    color: black;
}
