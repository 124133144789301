@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&display=swap');


.App {
  width: 100%;
  font-family: 'Crimson Text', serif;
}

.footer {
  display: flex;
  justify-content: center;
}

.linksList {
  display: flex;
  gap: min(calc(12px + 5vw), 80px);
}

.linksList a {
  display: block;
}

p, ol, ul {
  font-size: min(calc(15px + 0.4vw), 22px);
}

a {
  font-size: min(calc(15px + 0.4vw), 22px);
  color: #888;
  text-decoration: none;
}

hr {
  color: #888;
}

code {
  font-family: monospace;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ddd;
  background-color: #960000;
}

footer a {
  margin: 0px;
  color: white;
}

footer a:hover {
  color: black;
}
