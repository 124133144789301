@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400&display=swap);
html, body {
  margin: 0px;
  font-family: 'Crimson Text', serif;
  background-color: #fff;
}

.App_App__16ZpL {
  width: 100%;
  font-family: 'Crimson Text', serif;
}

.App_footer__29Fsv {
  display: flex;
  justify-content: center;
}

.App_linksList__2HhpQ {
  display: flex;
  grid-gap: min(calc(12px + 5vw), 80px);
  gap: min(calc(12px + 5vw), 80px);
}

.App_linksList__2HhpQ a {
  display: block;
}

p, ol, ul {
  font-size: min(calc(15px + 0.4vw), 22px);
}

a {
  font-size: min(calc(15px + 0.4vw), 22px);
  color: #888;
  text-decoration: none;
}

hr {
  color: #888;
}

code {
  font-family: monospace;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ddd;
  background-color: #960000;
}

footer a {
  margin: 0px;
  color: white;
}

footer a:hover {
  color: black;
}

.About_about__6ZqrC {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.About_p__1uNIQ {
  text-align: justify;
}

h1 {
  font-size: min(calc(24.545px + 0.6545vw), 36px);
}

h3 {
  font-size: min(calc(17.7272px + 0.4727vw), 26px);
  font-weight: bold;
  margin: 0px;
}

.About_profileImg__1loby {
  width: calc(50px + 18vw);
  height: calc(50px + 18vw);
  max-width: 256px;
  max-height: 256px;
  border-radius: 50%;
  background-color: white;
}

.About_projectList__2iYpU {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
}

.About_projectListItem__1FHxi {
  width: min(calc(200px + 30vw), 600px);
  display: flex;
  align-items: center;
  grid-gap: min(calc(5px + 1vw), 25px);
  gap: min(calc(5px + 1vw), 25px);
}

.About_projectThumb__3wOU7 {
  width: calc(60px + 10vw);
  height: calc(60px + 10vw);
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px 0px;
}

.About_blurb__whPH0 {
  width: 600px;
  font-size: min(calc(14px + 0.5vw), 22px);
}

.About_porthole__1T295 {
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.About_porthole__1T295 img {
  position: relative;
  width: 200px;
}

.NavBar_NavBar__3sjys {
    display: flex;
    justify-content: center;
}

.NavBar_emailz__2DP8k a {
    font-style: italic;
}

.NavBar_socialIconList__3rxw1 {
    margin-top: 10px;
    display: flex;
    grid-gap: min(calc(1050px + 0.4vw), 32px);
    
}

.NavBar_socialIconList__3rxw1 a {
    font-size: min(calc(1050px + 0.4vw), 32px);
    color: black;
}

.Projects_projects__3AHOa {

}

.Projects_projectList__2HcQB {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

header {
  margin-bottom: 40px;
}

.ProjectListItem_projectListItem__Fws7v {
  min-width: 300px;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.2s;
}

.ProjectListItem_projectListItem__Fws7v:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px 0px;
}

.ProjectListItem_projectListItemHeader__3mqMh {
  padding: 2px 8px;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #999;
  color: white;
}

.ProjectListItem_projectListItemBody__1hD4M {
  padding: 10px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  color: black;
  text-align: left;
}

.TagList_tagList__3PFUL {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  gap: 4px;
}

.TagList_tagListItem__Q2zNq {
  border: 1px solid #ddd;
  padding: 0px 4px;
  border-radius: 5px;
  color: #888;
}

.Project_gallery__Bx1bA {
  display: flex;
  justify-content: center;
}

.Project_gallery__Bx1bA img {
  margin: 40px 0px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px 0px;
}

