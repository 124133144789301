.projects {

}

.projectList {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

header {
  margin-bottom: 40px;
}
