.projectListItem {
  min-width: 300px;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.2s;
}

.projectListItem:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px 0px;
}

.projectListItemHeader {
  padding: 2px 8px;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #999;
  color: white;
}

.projectListItemBody {
  padding: 10px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  color: black;
  text-align: left;
}
